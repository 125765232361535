import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import useNotifications from "@notifications"

export default function useProvidersList() {

    // Use toast
	const { toastNotification } = useNotifications()

    const refProviderListTable = ref(null);

	const styleColumns = {
        thClass: 'text-center',
        tdClass: 'text-center'
    }

    // Table Handlers
    let tableColumns = [
        { key: 'name', sortable: true, label: 'PROVEEDOR', thClass: styleColumns.thClass },
        { key: 'business_area_name', sortable: true, label: 'RUBRO', ...styleColumns  },
		{ key: 'approval_state_name', sortable: true, label: 'ESTADO <br/> HOMOLOGACIÓN', ...styleColumns },
        { key: 'contract_numbers', sortable: true, label: 'NÚMERO(S) <br/> CONTRATO(S)', ...styleColumns  },
        { key: 'next_date_cycle', sortable: true, label: 'PRÓXIMA FECHA <br/> DEL CICLO', ...styleColumns  },
        { key: 'form_count', sortable: true, label: 'N° FORMULARIOS ASIGNADOS', ...styleColumns  },
        { key: 'actions', sortable: false, label: 'OPCIONES', ...styleColumns  }
    ];

    const perPage = ref(10);
    const totalProviders = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(true);

    const businessAreasFilter = ref(null);
	const approvalStatesFilter = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refProviderListTable.value ? refProviderListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalProviders.value,
        }
    });

    const refetchData = () => {
        refProviderListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, businessAreasFilter, approvalStatesFilter], () => {
        refetchData();
    });

    const fetchProviders = (ctx, callback) => {
        store
            .dispatch('app-provider/fetchProviders', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,

                businessAreas: businessAreasFilter.value,
				approvalStates: approvalStatesFilter.value,
            })
            .then(response => {
                const { providers, total } = response.data;
                callback(providers);
                totalProviders.value = total;
            })
            .catch(() => {
				toastNotification('Error al obtener la lista de proveedores', false)
            })
    };

    return {
        fetchProviders,
        tableColumns,
        perPage,
        currentPage,
        totalProviders,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refProviderListTable,

        refetchData,

        // Extra Filters
        businessAreasFilter,
        approvalStatesFilter
    }
}